import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';
import i18n from './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Helmet>
      <title>{i18n.t('metaTitle')}</title>
      <meta name={i18n.t('metaTitle') as string} content={i18n.t('metaDesc') as string} />
      <meta property="og:title" content={i18n.t('metaTitle') as string} />
      <meta property="og:description" content={i18n.t('metaDesc') as string} />
    </Helmet>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
