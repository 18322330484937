import React, { useEffect } from 'react';
import { BrowserRouter, HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import './App.css';
import Home from './Home';
import Pagamenti from './Pagamenti';
import Thankyou from './Thankyou';
import Bando from './Bando';
import Brevetto from './Brevetto';
//import LinkedInTag from 'react-linkedin-insight';

//LinkedInTag.init('5567145');
//LinkedInTag.track('13908241');

const tagManagerArgs = {
  gtmId: 'GTM-57HLXDG'
}
TagManager.initialize(tagManagerArgs)

function ScrollToElement() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return null;
}

function App() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.gohire.io/widget/wAuKB90h';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const { t } = useTranslation();

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ScrollToElement />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/en" element={<Home />} />
          <Route path="/come-pago" element={<Pagamenti />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/en/thank-you" element={<Thankyou />} />
          <Route path="/bando" element={<Bando />} />
          <Route path="/brevetto" element={<Brevetto />} />
          <Route path="/en/patented" element={<Brevetto />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
