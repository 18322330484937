import React, { useRef } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import '../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
    sourceValue: string;
}
  
function Form({ setInputValue, sourceValue }: Props) {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const { t } = useTranslation();

    const formRef = useRef<HTMLFormElement>(null);

    const [errorMessage, setErrorMessage] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const navigate = useNavigate();

    const inputRef = useRef<HTMLInputElement>(null);

    const setInputValueAndRef = (value: string) => {
        if (inputRef.current) {
        inputRef.current.value = value;
        }
        setInputValue(value);
    };


    const sendEmail = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formThis = event.currentTarget;

        const nameField = formThis.elements.namedItem('name') as HTMLInputElement;
        if (!nameField.value) {
            setErrorMessage('Required input: Nome');
            return;
        }
        const lastField = formThis.elements.namedItem('last') as HTMLInputElement;
        if (!lastField.value) {
            setErrorMessage('Required input: Cognome');
            return;
        }
        const companyField = formThis.elements.namedItem('company') as HTMLInputElement;
        if (!companyField.value) {
            setErrorMessage('Required input: Azienda');
            return;
        }
        const roleField = formThis.elements.namedItem('role') as HTMLInputElement;
        if (!roleField.value) {
            setErrorMessage('Required input: Ruolo');
            return;
        }
        const regionField = formThis.elements.namedItem('region') as HTMLInputElement;
        if (!regionField.value) {
            setErrorMessage('Required input: Provincia');
            return;
        }
        const emailField = formThis.elements.namedItem('email') as HTMLInputElement;
        if (!emailField.value) {
            setErrorMessage('Required input: E-mail');
            return;
        }
        const marketingField = formThis.elements.namedItem('marketing') as HTMLInputElement;
        if (!marketingField.checked) {
            setErrorMessage('Required: Accetto la privacy policy');
            return;
        }

        if (formThis) {
            emailjs.sendForm('service_325xurx', 'template_1k6ssn5', formThis, 'j7SLssmA9xcXhgi_H')
            .then(() => {
                setIsFormSubmitted(true);
                formThis.reset();
            }, (error) => {
                setIsFormSubmitted(false);
            });
        };
    }

    if (isFormSubmitted) {

        navigate('/thank-you');

        /**
        return (
            <div id='formOK' className='my-32 mx-auto bg-ffred text-center text-white font-semibold rounded-full text-3xl py-8 px-8 max-w-[640px]'>
                {t('form.success')}
            </div>
        );
        */
    };

    return (
        <section id='form' className='mt-24 mb-16 relative'>
            <div className='container before:bg-ffbluelight before:absolute before:-right-1/2 before:h-full before:w-4/12 before:right-0 before:-z-10 before:hidden md:before:block'>
                <div className='relative px-8 md:px-0 bg-ffbluelight md:rounded-tl-3xl md:rounded-bl-3xl md:pl-24 pb-24'>
                    <h2 data-aos="fade-up" className='text-center md:text-left text-ffblue text-4xl font-bold leading-tight pt-24 pb-6'>{t('form.title')}</h2>
                    <p data-aos="fade-up" data-aos-delay="250" className='text-center md:text-left text-ffblue text-xl md:text-xl max-w-[640px] pb-16'>{t('form.desc')}</p>
                    <form data-aos="fade-up" className='md:pl-32' ref={formRef} onSubmit={sendEmail}>                        
                        <div className='md:flex gap-8 mb-6'>
                            <div className='basis-1/2 mb-6 md:mb-0'>
                                <input placeholder={t('form.name') as string} type="text" id='name' name="name" className='rounded-lg w-full bg-white py-3 px-3 placeholder:text-ffblue text-xl border border-ffblue focus-visible:text-ffblue' />
                            </div>
                            <div className='basis-1/2 mb-6 md:mb-0'>
                                <input placeholder={t('form.cognome') as string} type="text" name="last" className='rounded-lg w-full bg-white py-3 px-3 placeholder:text-ffblue text-xl border border-ffblue focus-visible:text-ffblue' />
                            </div>
                        </div>
                        <div className='md:flex gap-8 mb-6'>
                            <div className='basis-1/2 mb-6 md:mb-0'>
                                <input placeholder={t('form.azienda') as string} type="text" name="company" className='rounded-lg w-full bg-white py-3 px-3 placeholder:text-ffblue text-xl border border-ffblue focus-visible:text-ffblue' />
                            </div>
                            <div className='basis-1/2 mb-6 md:mb-0'>
                                <input placeholder={t('form.ruolo') as string} type="text" name="role" className='rounded-lg w-full bg-white py-3 px-3 placeholder:text-ffblue text-xl border border-ffblue focus-visible:text-ffblue' />
                            </div>
                        </div>
                        <div className='md:flex gap-8 mb-6'>
                            <div className='basis-1/2 mb-6 md:mb-0'>
                                <input placeholder={t('form.provincia') as string} type="text" name="region" className='rounded-lg w-full bg-white py-3 px-3 placeholder:text-ffblue text-xl border border-ffblue focus-visible:text-ffblue' />
                            </div>
                            <div className='basis-1/2 mb-6 md:mb-0'>
                                <input placeholder={t('form.numero') as string} type="number" name="_num" className='rounded-lg w-full bg-white py-3 px-3 placeholder:text-ffblue text-xl border border-ffblue focus-visible:text-ffblue' />
                            </div>
                        </div>
                        <div className='md:flex gap-8 mb-6'>
                            <div className='basis-full'>
                                <input placeholder='E-mail *' type="email" name="email" className='rounded-lg w-full bg-white py-3 px-3 placeholder:text-ffblue text-xl border border-ffblue focus-visible:text-ffblue' />
                            </div>
                        </div>
                        <div className='md:flex gap-8 mb-6'>
                            <div className='basis-full'>
                                <textarea placeholder={t('form.msg') as string} name="_message" className='rounded-lg w-full h-42 bg-white py-3 px-3 placeholder:text-ffblue text-xl border border-ffblue focus-visible:text-ffblue'></textarea>
                            </div>
                        </div>                    
                        <div className='relative mt-12'>
                            <div className='text-ffblue font-medium text-lg pl-0 md:pl-12 pb-3'>
                                <label className="check">
                                    <input type="checkbox" name="_whitepaper" id="whitepaperCheck" />
                                    <span className="checkmark"></span>
                                </label>
                                <div className='pl-10'>{t('form.ch1')}</div>
                            </div>
                            <div className='text-ffblue font-medium text-xl pl-0 md:pl-12 pb-10'>
                                <label className="check">
                                    <input type="checkbox" name='marketing' />
                                    <span className="checkmark"></span>
                                </label>
                                <div className='pl-10'>{t('form.ch2')}<a href="https://www.iubenda.com/privacy-policy/87567201" target="_blank" className='underline'>privacy policy</a> *</div>
                            </div>
                            <div className='flex'>
                                <input type="hidden" name="source" id='source' ref={inputRef} value={sourceValue} />
                                <button type="submit" className="cta font-poppins text-white bg-ffred px-10 py-4 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30">{t('form.btn')}</button>
                                {errorMessage && <div className='mb-6 text-ffred ml-4 rounded-full text-lg pt-8 pl-4'>{errorMessage}</div>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Form