import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const i18nOptions: InitOptions = {
  fallbackLng: 'it',
  debug: true,
  resources: {
    it: {
      translation: require('./i18n/it.json'),
    },
    en: {
      translation: require('./i18n/en.json'),
    },
  },
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['path', 'navigator'],
    lookupFromPathIndex: 0
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nOptions);

export default i18n;