import React, { useState } from 'react';
import config from '../config/index.json';
import { Link } from 'react-scroll';
import '../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function Perche({ setInputValue }: Props) {

    const handleClick = () => {
        setInputValue('Perche');
    };

    const handleLinkClick = () => {
        handleClick();
    };

    const { t } = useTranslation();

    const { images } = config;
    const { close, iconHeartHover, arrowRight, arrowAngle } = images;
    
    interface AccordionSection {
        number: string;
        title: string;
        content: string;
    }

    const Accordion: React.FC<{ sections: AccordionSection[] }> = ({ sections }) => {
        const [activeSection, setActiveSection] = useState<number | null>(0); // Ustaw indeks pierwszego panelu jako początkową wartość

        const toggleSection = (index: number) => {
            if (index === activeSection) {
                setActiveSection(null);
            } else {
                setActiveSection(index);
            }
        };
    
        return (
        <div className="accordion">
            {sections.map((section, index) => (
                <div key={index} className={`accordion-section py-12 ${activeSection === index ? 'active' : ''}`}>
                    <div className='container relative'>
                        <span className='absolute -left-12 text-4xl font-poppins font-bold text-ffblue'>{section.number}</span>
                        <div
                        className="accordion-title text-4xl mb-4 font-poppins font-bold text-ffblue cursor-pointer"
                        onClick={() => toggleSection(index)}
                        >
                        {section.title}
                        </div>
                        <div
                        className="accordion-content w-3/4"
                        style={{
                            maxHeight: activeSection === index ? '1000px' : '0',
                            overflow: 'hidden',
                            transition: 'all .25s ease',
                        }}
                        >
                        <div
                            className="content-inner text-ffblue pb-8"
                            dangerouslySetInnerHTML={{ __html: section.content }}
                        />
                        </div>
                        <button
                            className="toggle-button"
                            onClick={() => toggleSection(index)}
                            >
                            <img
                                className='inline-block w-16 mr-4 relative -mt-1'
                                src={activeSection === index ? '' : arrowRight}
                                />
                            <span
                                className='font-poppins text-lg text-ffblue'
                                dangerouslySetInnerHTML={{
                                    __html: activeSection === index ? '' : t('perche.more'),
                                }}
                            />
                        </button>
                    </div>
                </div>
            ))}
        </div>
        );
    };
    

    const sections: AccordionSection[] = [
        {
            number: '1',
            title: t('perche.a1title'),
            content: t('perche.a1desc')
        },
        {
            number: '2',
            title: t('perche.a2title'),
            content: t('perche.a2desc')
        },
        {
            number: '3',
            title: t('perche.a3title'),
            content: t('perche.a3desc')
        },
        {
            number: '4',
            title: t('perche.a4title'),
            content: t('perche.a4desc')
        },
    ];

    return (
        <section id='perche' className='border-[30px] border-[#00A8C7]'>
            <div>
                <div className='container relative pt-20'>
                    <div className='mx-auto my-0'>
                        <span className='tagLabel block md:inline-block bg-ffblue text-white text-left text-sm md:text-lg rounded-full pl-2 md:pl-6 pr-2 md:pr-12 py-3 font-poppins font-normal relative left-0 md:-left-4'>
                            <img src={iconHeartHover} className='inline-block w-8 md:w-10 mr-2 md:mr-4' />{t('perche.tag')}
                        </span>
                        <h3 className='text-5xl mb-2 font-poppins font-bold text-ffblue pb-4 pt-12'>{t('perche.h31')}<br />{t('perche.h32')}</h3>
                        <div className='text-ffblue w-1/2 pb-8' dangerouslySetInnerHTML={{ __html: t('perche.desc') }}></div>
                    </div>
                </div>
                <Accordion sections={sections} />
                <div className='bg-white text-center text-ffblue py-16'>
                    <div className='pb-8' dangerouslySetInnerHTML={{ __html: t('perche.foot') }}></div>
                    <Link
                        to='form'
                        smooth={true}
                        data-aos="fade-up" data-aos-delay="200"
                        onClick={handleLinkClick}
                        data-value='Perche'
                        className='cta font-poppins text-white bg-[#f83f76] px-7 py-3 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all btnWhitepaper'>{t('contact.btn')}</Link>
                </div>
            </div>
        </section>
    )

}

export default Perche