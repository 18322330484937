import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import i18n from './i18n';
import { Link } from 'react-router-dom';
import { NavLink as RouterLink } from "react-router-dom";
import config from './config/index.json';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function Brevetto() {

    const [sourceValue, setSourceValue] = useState('');
    const { t } = useTranslation();

    const handleSetSourceValue = (value: string) => {
      setSourceValue(value);
    };

    const { images } = config;
    const { logo, tornasu, backtop, iconPatent, footHeadline, pon, iconfb, iconig, iconyt, iconin } = images;
    const language = i18n.language;
    const imageSource = language === 'en' ? backtop : tornasu;

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const navigation = [
        { name: t('navigation.frescofrigo'), extLink: t('extLink.frescofrigo') },
        { name: t('navigation.perche'), extLink: t('extLink.perche') },
        { name: t('navigation.advantages'), extLink: t('extLink.advantages') },
        { name: t('navigation.comefunziona'), extLink: t('extLink.comefunziona') },
        { name: t('navigation.tecnologia'), extLink: t('extLink.tecnologia') },
        { name: t('navigation.chisiamo'), extLink: t('extLink.chisiamo') }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const link = t('brev.fbtnlink') as string;
    
    return (
        <div id='Brevetto'>
            <Helmet>
                <title>{i18n.t('metaTitle')}</title>
                <meta name={i18n.t('metaTitle') as string} content={i18n.t('metaDesc') as string} />
                <meta property="og:title" content={i18n.t('metaTitle') as string} />
                <meta property="og:description" content={i18n.t('metaDesc') as string} />
            </Helmet>
            <div id="header" className='sticky w-full top-0 bg-ffblue z-20 pt-4 md:pt-8 pb-4 px-4 md:px-16'>
                <div className='flex justify-center md:justify-between flex-col-reverse	md:flex-row'>
                    <div className='px-4 flex items-center'>
                        <Link to="/"><img src={logo} className="mt-16 md:my-4 w-40 block mx-auto" alt="logo" /></Link>
                    </div>
                    <div className='md:flex items-center text-right'>
                        <ul className="items-center">
                        {navigation.map((item) => (
                            <Link to={item.extLink}
                                className="hidden md:inline text-white text-lg font-medium mr-10 cursor-pointer transition hover:border-b-2"
                                >
                                {item.name}
                            </Link>
                        ))}
                        <Link to="/#form"
                            className="hidden md:inline text-white bg-ffred px-6 py-3 text-lg rounded-full font-bold cursor-pointer cta btnDemo"
                            >
                            {t('requestDemo')}
                        </Link>
                        <Link to="/brevetto" className='cursor-pointer py-4 px-2 text-white text-sm ml-4 font-medium opacity-80 hover:opacity-100 transition' onClick={() => changeLanguage('it')}>IT</Link>
                        <Link to="/en/patented" className='cursor-pointer py-4 px-2 text-white text-sm font-medium opacity-80 hover:opacity-100 transition' onClick={() => changeLanguage('en')}>EN</Link>
                        </ul>
                    </div>
                </div>
                <a href='#' className='backTop'><img src={imageSource} className='w-16' /></a>
            </div>
            <div className='bg-ffblue'>
                <div className='container pt-8 md:pt-36 py-16'>
                    <div className='relative max-w-[480px] mx-auto'>
                        <h1 className='text-white text-center text-4xl md:text-4xl font-bold pb-8' dangerouslySetInnerHTML={{ __html: t('brev.head') }}></h1>
                        <img src={iconPatent} className="my-4 w-20 md:w-26 mx-auto static md:absolute -left-20 -bottom-1" />
                    </div>
                    <p className='text-white text-xl px-8 md:px-0 max-w-[720px] mx-auto my-0 text-center' dangerouslySetInnerHTML={{ __html: t('brev.headp') }}></p>
                </div>
            </div>
            <div className='bg-white'>
                <div className='container py-24'>
                    <h3 className='text-ffblue text-center text-3xl font-bold pb-4 px-4 md:px-0'>{t('brev.comehead')}</h3>
                    <p className='text-ffblue text-lg max-w-[600px] mx-auto my-0 text-center px-4 md:px-0'>{t('brev.comep')}</p>
                </div>
            </div>
            <div className='bg-ffblue/[0.1]'>
                <div className='py-24 max-w-[1100px] mx-auto my-0'>
                    <h3 className='text-ffblue text-center text-3xl font-bold pb-12'>{t('brev.ahead')}</h3>
                    <div className='block md:grid grid-cols-2'>
                        <div className='box box01 bg-ffblue/[0.08] min-h-[300px] rounded-2xl flex px-4 py-8 m-3 relative cursor-default transition-all ease-in-out duration-700 hover:bg-ffblue'>
                            <div className='ico'></div>
                            <div>
                                <h3 className='text-ffblue text-3xl font-bold pb-4' dangerouslySetInnerHTML={{ __html: t('brev.a1head') }}></h3>
                                <p className='text-ffblue'>{t('brev.a1p')}</p>
                            </div>
                        </div>
                        <div className='box box02 bg-ffblue/[0.08] min-h-[300px] rounded-2xl flex px-4 py-8 m-3 relative cursor-default transition-all ease-in-out duration-700 hover:bg-ffblue'>
                            <div className='ico'></div>
                            <div>
                                <h3 className='text-ffblue text-3xl font-bold pb-4' dangerouslySetInnerHTML={{ __html: t('brev.a2head') }}></h3>
                                <p className='text-ffblue'>{t('brev.a2p')}</p>
                            </div>
                        </div>
                        <div className='box box03 bg-ffblue/[0.08] min-h-[300px] rounded-2xl flex px-4 py-8 m-3 relative cursor-default transition-all ease-in-out duration-700 hover:bg-ffblue'>
                            <div className='ico'></div>
                            <div>
                                <h3 className='text-ffblue text-3xl font-bold pb-4' dangerouslySetInnerHTML={{ __html: t('brev.a3head') }}></h3>
                                <p className='text-ffblue'>{t('brev.a3p')}</p>
                            </div>
                        </div>
                        <div className='box box04 bg-ffblue/[0.08] min-h-[300px] rounded-2xl flex px-4 py-8 m-3 cursor-default transition-all ease-in-out duration-700 hover:bg-ffblue'>
                            <div className='ico'></div>
                            <div>
                                <h3 className='text-ffblue text-3xl font-bold pb-4' dangerouslySetInnerHTML={{ __html: t('brev.a4head') }}></h3>
                                <p className='text-ffblue'>{t('brev.a4p')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='py-32 text-center'>
                <h5 className='text-ffblue text-3xl font-medium px-6 md:px-0 max-w-[620px] mx-auto my-0 text-center'>{t('brev.fhead')}</h5>
                <p className='text-[#E8477D] text-lg max-w-[520px] mx-auto my-0 text-center font-medium pt-8 pb-12 px-0 md:px-6'>{t('brev.fp')}</p>
                <Link to={link}
                    className='cta btnDemo font-poppins text-white bg-ffred mx-auto my-0 px-14 py-4 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30 hover:bg-[#FF3478] btnContattaci'>{t('brev.fbtn')}</Link>
            </div>
            <section id='footer' className='bg-ffblue pt-16'>
                <div className='md:flex flex-row gap-12 px-8 md:px-16'>
                    <div className='basis-3/12'>
                        <img src={logo} className='logoFoot' />
                    </div>
                    <div className='basis-4/12'>
                        <ul className="items-center">
                            {navigation.map((item) => (
                                <li>
                                <Link to={item.extLink}
                                >
                                {item.name}
                                </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='basis-4/12'>
                        <ul className="items-center">
                            <li>
                                <RouterLink to="/bando/">Bando Nazionale Smart&Start Italia</RouterLink>
                            </li>
                            <li>
                                <a href={`${t('work.slug')}`}>{t('work.title')}</a>
                            </li>
                        </ul>
                    </div>
                    <div className='basis-4/12'>
                        <ul>
                            <li><a href='https://www.facebook.com/frescofrigo/' target='_blank'><img src={iconfb} className='w-2' /></a></li>
                            <li><a href='https://www.instagram.com/frescofrigo/' target='_blank'><img src={iconig} className='w-5' /></a></li>
                            <li><a href='https://www.youtube.com/channel/UCYFxiMavyy_Ttz_VJCRy4Xw/featured' target='_blank'><img src={iconyt}className='w-6' /></a></li>
                            <li><a href='https://www.linkedin.com/company/frescofrigo/' target='_blank'><img src={iconin} className='w-5' /></a></li>
                        </ul>
                    </div>
                    <div className='basis-4/12 flex flex-col md:place-items-end mt-8 md:mt-0'>
                        <a href='#'><img src={imageSource} className='w-8 md:w-16 backTopStatic' /></a>
                    </div>
                </div>
                <div className='basis-1/1 px-8 md:px-16 pt-8 md:pt-16 pb-16'>
                    <img src={footHeadline} className='w-full md:w-[80%] my-0 mx-auto' />
                </div>
                <div className='endBar bg-white md:flex flex-row px-8 md:px-16 pt-2'>
                    <div>
                        <img src={pon} className='w-full max-w-[360px] mb-2' />
                    </div>
                    <div className='secNav'>
                        <RouterLink to="/come-pago/">{t('footer.secure')}</RouterLink>
                        <a href={t('footer.pLink') as string}>Privacy</a>
                        <a href="https://www.iubenda.com/privacy-policy/87567201/cookie-policy">Cookies</a>
                        <p>FrescoFrigo Srl - P.IVA 04585380233</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Brevetto;