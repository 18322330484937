import React from 'react';
import { NavLink as RouterLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from 'react-scroll';
import config from '../config/index.json';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function Brev() {

    const { images } = config;
    const { logo1, logo2, logo3, logo4, logo5, logo6, ffhomepatentmotion } = images;

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 2000 });
      }, []);

    return (
        <section className='pt-16 pb-32'>
        <div className='container md:px-0 px-8'>
            <div className='md:flex max-w-[800px]'>
                <div className='basis-4/12 text-center'>
                    <img src={ffhomepatentmotion} className='w-full max-w-[180px] md:max-w-[100%] mx-auto my-0' />
                </div>
                <div className='basis-7/12 text-center'>
                    <h2 data-aos="fade-up" className='text-ffblue text-center text-4xl md:text-3xl font-bold md:leading-tight pb-6 mt-8 md:mt-0'>{t('bintro.head')}</h2>
                    <p data-aos="fade-up" data-aos-delay="300" className='text-center text-md md:text-lg text-ffblue pb-6' dangerouslySetInnerHTML={{ __html: t('bintro.p') }}></p>
                    <RouterLink to={i18n.t('bintro.link') as string} className='cta font-poppins text-white bg-ffblue px-7 py-3 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30 btnWhitepaper'>{t('bintro.btn')}</RouterLink>
                </div>
            </div>
            
        </div>
    </section>
    )
}

export default Brev