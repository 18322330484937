import React, { useEffect, useState } from 'react';
import { Link } from "react-scroll";
import { NavLink as RouterLink } from "react-router-dom";
import config from '../config/index.json';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function Header({ setInputValue }: Props) {

    const { t } = useTranslation();

    const handleClick = () => {
      setInputValue('Header');
    };

    const { images } = config;
    const { logo, tornasu, backtop, backtopMobile } = images;
    const language = i18n.language;
    const imageSource = language === 'en' ? backtop : tornasu;

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    
          if (scrollPosition > 1000) {
            const backTopElement = document.querySelector('.backTop');
            if (backTopElement) {
              backTopElement.classList.add('fadeIn');
            }
          } else {
            const backTopElement = document.querySelector('.backTop');
            if (backTopElement) {
              backTopElement.classList.remove('fadeIn');
            }
          }
        };
    
        // Dodajemy nasłuchiwanie zdarzenia przewijania
        window.addEventListener('scroll', handleScroll);
    
        // Usuwamy nasłuchiwanie zdarzenia po zniszczeniu komponentu (czyszczenie)
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const navigation = [
        { name: t('navigation.frescofrigo'), href: t('href.frescofrigo') },
        { name: t('navigation.perche'), href: t('href.perche') },
        { name: t('navigation.advantages'), href: t('href.advantages') },
        { name: t('navigation.comefunziona'), href: t('href.comefunziona') },
        { name: t('navigation.tecnologia'), href: t('href.tecnologia') },
        { name: t('navigation.chisiamo'), href: t('href.chisiamo') }
    ];

    return (
        <div id="header" className='sticky w-full top-0 bg-ffblue z-20 pt-4 md:pt-8 pb-4 px-4 md:px-16'>
            <div className='flex justify-center md:justify-between flex-col-reverse	md:flex-row'>
                <div className='px-4 flex items-center'>
                    <img src={logo} className="mt-16 md:my-4 w-40 block mx-auto" alt="logo" />
                </div>
                <div className='md:flex items-center text-right'>
                    <ul className="items-center">
                    {navigation.map((item) => (
                        <Link
                            spy={true}
                            smooth={true}
                            duration={1000}
                            key={item.name}
                            to={item.href}
                            className="hidden md:inline text-white text-lg font-medium mr-10 cursor-pointer transition hover:border-b-2"
                            >
                            {item.name}
                        </Link>
                    ))}
                    <Link
                        spy={true}
                        smooth={true}
                        to="form"
                        onClick={handleClick}
                        className="hidden md:inline text-white bg-ffred px-6 py-3 text-lg rounded-full font-bold cursor-pointer cta btnDemo"
                        >
                        {t('requestDemo')}
                    </Link>
                    <RouterLink to="/" className='cursor-pointer py-4 px-2 text-white text-sm ml-4 font-medium opacity-80 hover:opacity-100 transition' onClick={() => changeLanguage('it')}>IT</RouterLink>
                    <RouterLink to="/en" className='cursor-pointer py-4 px-2 text-white text-sm font-medium opacity-80 hover:opacity-100 transition' onClick={() => changeLanguage('en')}>EN</RouterLink>
                    </ul>
                </div>
            </div>
            <a href='#' className='backTop'>
              <img src={imageSource} className='w-16 desktopOnly' />
              <img src={backtopMobile} className='w-16 mobileOnly' />
            </a>
        </div>
    )
}

export default Header