import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from 'react-scroll';
import config from '../config/index.json';
import checkCheckbox from './checkCheckbox';
import '../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function Benefits({ setInputValue }: Props) {

    const handleClick = () => {
      setInputValue('Tabella');
    };

    const { navigation, images } = config;
    const { benefit1, benefit2, benefit3, table1, table2, table3, check, empty, unicita, close } = images;

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const handleLinkClick = () => {
        handleClick();
    };

    const handleLinkClickPaper = () => {
        checkCheckbox();
        handleClick();
    };

    return (
        <section id={t('href.soluzioni') as string} className='pt-24 border-[30px] border-[#A0D882]'>
        <div className='container relative pt-20'>
                <div data-aos="fade-up" className='text-center'>
                <span data-aos="fade-up" className='tagLabel inline-block text-left text-white bg-[#A0D882] text-sm md:text-lg rounded-full pl-2 md:pl-6 pr-4 md:pr-12 py-3 font-poppins font-normal relative left-0 md:-left-4'>
                        <img src={unicita} className='inline-block w-8 md:w-8 mr-2 md:mr-4' />
                        {t('benefits.tag')}
                    </span>
                </div>
                <h2 data-aos="fade-up" className='text-ffblue text-4xl md:text-5xl font-bold md:leading-tight text-center pt-12 pb-6'>{t('benefits.title')}</h2>
                <div className='block md:grid grid-cols-3 pt-16 gap-20'>
                    <div>
                        <div data-aos="fade-up" className='relative cursor-pointer pb-24 md:pb-0'>
                            <img src={benefit1} />
                            <h4 className='text-ffblue text-2xl md:text-2xl font-semibold pt-8'>{t('benefits.t1')}</h4>
                            <p className='text-ffblue pt-4 text-xl md:text-xl'>{t('benefits.p1')}</p>
                        </div>
                    </div>
                    <div>
                        <div data-aos="fade-up" data-aos-delay="250" className='relative cursor-pointer pb-24 md:pb-0'>
                            <img src={benefit2} />
                            <h4 className='text-ffblue text-2xl md:text-2xl font-semibold pt-8'>{t('benefits.t2')}</h4>
                            <p className='text-ffblue pt-4 text-xl md:text-xl'>{t('benefits.p2')}</p>
                        </div>
                    </div>
                    <div>
                        <div data-aos="fade-up" data-aos-delay="500" className='relative cursor-pointer pb-24 md:pb-0'>
                            <img src={benefit3} />
                            <h4 className='text-ffblue text-2xl md:text-2xl font-semibold pt-8'>{t('benefits.t3')}</h4>
                            <p className='text-ffblue pt-4 text-xl md:text-xl'>{t('benefits.p3')}</p>
                        </div>
                    </div>
                </div>
                <div className='mt-16 md:mt-48'>
                    <div data-aos="fade-up" className='grid grid-cols-4 gap-0 md:gap-8 pb-8'>
                        <div></div>
                        <div className='text-center bg-ffbluelight rounded-full w-full h-40 md:h-56 relative'>
                            <img src={table1} className='w-16 md:w-20 inline-block absolute top-6 left-0 right-0 mx-auto my-0' />
                            <div className='w-full h-12 font-poppins text-ffblue text-xs md:text-lg font-semibold absolute bottom-0 md:bottom-6 left-0 right-0 mx-auto my-0 md:break-normal break-all md:leading-none leading-none'>Fresco<i className='md:hidden'><br /></i>Frigo</div>
                        </div>
                        <div className='text-center bg-ffbluelight/50 rounded-full w-full h-40 md:h-56 relative'>
                            <img src={table2} className='w-16 md:w-20 inline-block absolute top-6 left-0 right-0 mx-auto my-0' />
                            <div className='w-full h-12 font-poppins text-ffblue text-xs md:text-lg font-semibold absolute bottom-0 md:bottom-6 left-0 right-0 mx-auto my-0 md:leading-none leading-none'>{t('benefits.h2')}</div>
                        </div>
                        <div className='text-center bg-ffbluelight/50 rounded-full w-full h-40 md:h-56 relative'>
                            <img src={table3} className='w-16 md:w-20 inline-block absolute top-6 left-0 right-0 mx-auto my-0' />
                            <div className='h-12 font-poppins text-ffblue text-xs md:text-lg font-semibold absolute bottom-0 md:bottom-6 left-0 right-0 mx-auto my-0 md:leading-none leading-none'>{t('benefits.h3')}<br /><small>{t('benefits.h4')}</small></div>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="50" className='grid grid-cols-4 gap-8 border-b border-neutral-300 py-2 items-center'>
                        <div className='font-poppins text-ffblue text-xs md:text-lg font-medium md:font-semibold pl-0 md:pl-6'>{t('benefits.h5')}</div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="100" className='grid grid-cols-4 gap-8 border-b border-neutral-300 py-2 items-center'>
                        <div className='font-poppins text-ffblue text-xs md:text-lg font-medium md:font-semibold pl-0 md:pl-6'>{t('benefits.h6')}</div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={empty} className='w-6 md:w-8 mx-auto my-0' /></div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="150" className='grid grid-cols-4 gap-8 border-b border-neutral-300 py-2 items-center'>
                        <div className='font-poppins text-ffblue text-xs md:text-lg font-medium md:font-semibold pl-0 md:pl-6'>{t('benefits.h7')}</div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={empty} className='w-6 md:w-8 mx-auto my-0' /></div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className='grid grid-cols-4 gap-8 border-b border-neutral-300 py-2 items-center'>
                        <div className='font-poppins text-ffblue text-xs md:text-lg font-medium md:font-semibold pl-0 md:pl-6'>{t('benefits.h8')}</div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={empty}className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={empty} className='w-6 md:w-8 mx-auto my-0' /></div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="250" className='grid grid-cols-4 gap-8 border-b border-neutral-300 py-2 items-center'>
                        <div className='font-poppins text-ffblue text-xs md:text-lg font-medium md:font-semibold pl-0 md:pl-6'>{t('benefits.h9')}</div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={empty}className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={empty} className='w-6 md:w-8 mx-auto my-0' /></div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="300" className='grid grid-cols-4 gap-8 border-b border-neutral-300 py-2 items-center'>
                        <div className='font-poppins text-ffblue text-xs md:text-lg font-medium md:font-semibold pl-0 md:pl-6'>{t('benefits.h10')}</div>
                        <div><img src={check} className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={empty}className='w-6 md:w-8 mx-auto my-0' /></div>
                        <div><img src={empty} className='w-6 md:w-8 mx-auto my-0' /></div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="350" className='lastRow text-center border-neutral-300 py-8 items-center'>
                        <div className='font-poppins text-ffblue text-sm pl-0 md:pl-6'>
                            <div className='text-neutral-500 text-center md:text-left'><strong>{t('benefits.dim')}</strong><br />{t('benefits.dim2')}</div>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-up" className='py-8 pb-24 text-center text-ffblue'>
                    <p className='pb-8 text-base'>{t('benefits.ctap')}</p>
                    <Link
                        to='form'
                        smooth={true}
                        data-aos="fade-up" data-aos-delay="200"
                        onClick={handleLinkClickPaper}
                        data-value='Chi siamo'
                        className='cta font-poppins text-white bg-[#f83f76] px-7 py-3 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30 btnWhitepaper'>{t('benefits.cta')}</Link>
                </div>
            </div>
        </section>
    )
}

export default Benefits