import React, { useState, useEffect } from 'react';

import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import Footer from './components/Footer';

import config from './config/index.json';

function Pagamenti() {

    const [sourceValue, setSourceValue] = useState('');

    const handleSetSourceValue = (value: string) => {
      setSourceValue(value);
    };

    const { navigation, images } = config;
    const { logo } = images;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    

    return (
        <div>
            <div className='bg-ffblue py-24'>
                <div className='container'>
                    <div className='text-center pb-24'>
                        <RouterLink to="/">
                            <img src={logo} className="my-4 w-52 mx-auto" alt="logo" />
                        </RouterLink>
                    </div>
                    <h1 className='text-white font-poppins md:text-7xl text-4xl font-bold pb-12 text-center'>Security Measures</h1>
                    <div className='max-w-[640px] mx-auto my-0 px-8 md:px-0'>
                        <p className='text-white text-xl font-medium'>FrescoFrigo demands the management of credit cards and the billing process to a third party. The whole FrescoFrigo owned infrastructure and the integration with said party follows the same guidelines and best practices.<br />Specifically, the party managing the credit cards data has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available in the payments industry. </p>
                        <h3 className='text-white font-poppins text-3xl md:text-4xl font-bold pt-16 pb-4'>HTTPS and HSTS for secure connections </h3>
                        <p className='text-white text-xl font-medium'>HTTPS encrypted communication is forced for all communications (fridge, app, dashboard, third parties) using TLS (SSL).<br />We regularly audit the details of our implementation, including the certificates we serve, the certificate authorities we use, and the ciphers we support.</p>
                        <h3 className='text-white font-poppins text-3xl md:text-4xl font-bold pt-16 pb-4'>Encryption of sensitive data and communication </h3>
                        <p className='text-white text-xl font-medium'>All card numbers are encrypted at rest with AES-256. Decryption keys are stored on separate machines. None of the internal servers and daemons can obtain plaintext card numbers but can request that cards are sent to a service provider on a static allowlist. The infrastructure for storing, decrypting, and transmitting card numbers runs in a separate hosting environment, and doesn’t share any credentials with the primary services (API, website, etc.).</p>
                        <h3 className='text-white font-poppins text-3xl md:text-4xl font-bold pt-16 pb-4'>Vulnerability disclosure and reward program </h3>
                        <p className='text-white text-xl font-medium'>The party managing the credit cards maintains a private, invite-only bug bounty program, with the assistance of HackerOne. Invited researchers are eligible for a payment. </p>
                        <br /><br /><br /><br />
                        <p className='text-sm text-white text-center'>FrescoFrigo Srl - P.IVA 04585380233</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pagamenti;