import React, { useState, useEffect } from 'react';

import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";

import config from './config/index.json';
import './i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
    sourceValue: string;
}

function Pagamenti() {

    const [sourceValue, setSourceValue] = useState('');

    const handleSetSourceValue = (value: string) => {
      setSourceValue(value);
    };

    const { navigation, images } = config;
    const { logo } = images;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const { t } = useTranslation();

    return (
        <div>
            <div className='bg-ffblue py-24 h-full absolute w-full'>
                <div className='container text-center'>
                    <div className='text-center pb-24'>
                        <RouterLink to="/">
                            <img src={logo} className="my-4 w-52 mx-auto" alt="logo" />
                        </RouterLink>
                    </div>
                    <h1 className='text-white font-poppins md:text-4xl text-xl font-bold pb-24 px-4 text-center'>{t('form.success')}</h1>
                    <p className='center'>
                        <RouterLink to={t('footer.slugLang') as string} className='text-white underline'>{t('form.backtohp')}</RouterLink>
                    </p>                        
                </div>
            </div>
        </div>
    );
}

export default Pagamenti;