import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import i18n from './i18n';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LazyShow from './components/LazyShow';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import How from './components/How';
import Smart from './components/Smart';
import Benefits from './components/Benefits';
import FoodBusiness from './components/FoodBusiness';
import Contact from './components/Contact';
import About from './components/About';
import Form from './components/Form';
import Footer from './components/Footer';
import Industria from './components/Industria';
import Windows from './components/Windows';
import Brev from './components/Brev';

function Home() {

    const [sourceValue, setSourceValue] = useState('');

    const handleSetSourceValue = (value: string) => {
      setSourceValue(value);
    };

    return (
        <div>
            <Helmet>
                <title>{i18n.t('metaTitle')}</title>
                <meta name={i18n.t('metaTitle') as string} content={i18n.t('metaDesc') as string} />
                <meta property="og:title" content={i18n.t('metaTitle') as string} />
                <meta property="og:description" content={i18n.t('metaDesc') as string} />
            </Helmet>
            <LazyShow>
                <>
                <Header setInputValue={setSourceValue} />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <Hero setInputValue={setSourceValue} />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <Features />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <Windows setInputValue={setSourceValue} />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <How setInputValue={setSourceValue} />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <Smart />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <Brev />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <Contact setInputValue={setSourceValue} />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <Industria />
                </>
            </LazyShow>
            <LazyShow>
                <>
                <Form setInputValue={setSourceValue} sourceValue={sourceValue} />
                </>
            </LazyShow>
            <Footer />
        </div>
    );
}

export default Home;