import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-scroll';
import React, { useEffect } from 'react';
import { VideoPlayer } from "./Video";
import config from '../config/index.json';
import checkCheckbox from './checkCheckbox';
import '../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function How({ setInputValue }: Props) {

    const handleClick = () => {
      setInputValue('Come funziona');
    };

    const { images } = config;
    const { videoPreview, video, play } = images;

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const handleLinkClick = () => {
        checkCheckbox();
        handleClick();
    };

    return (
        <section id={t('href.comefunziona') as string} className='pb-8'>
            <div className='container md:px-0 px-8'>
                <div className='md:flex'>
                    <div className='basis-5/12 md:pr-16 text-center'>
                        <a href="https://www.youtube.com/watch?v=7pSMgHawcwM" data-aos="fade-up" rel="noreferrer" target="_blank" className='relative cursor-pointer hover:scale-105 transition-all'>
                            <VideoPlayer
                                hlsSrc={videoPreview}
                                posterSrc=""
                                className="rounded-md"
                            />
                            <img src={play} className='absolute left-0 right-0 mx-auto my-0 bottom-8 w-16' alt="play" />
                        </a>
                        <br /><br />
                        <Link
                                to='form'
                                smooth={true}
                                data-aos="fade-up" data-aos-delay="200"
                                onClick={handleLinkClick}
                                data-value='Come funziona'
                                className='cta font-poppins text-white bg-ffblue px-7 py-3 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30 btnWhitepaper'>{t('downloadWhitepaper')}</Link>
                    </div>
                    <div className='basis-7/12'>
                        <h2 data-aos="fade-up" className='text-ffblue text-center md:text-left text-4xl md:text-5xl font-bold md:leading-tight pt-12 pb-6'>{t('how.title')}<br />{t('how.title2')}</h2>
                        <p data-aos="fade-up" data-aos-delay="300" className='text-xl text-center md:text-left md:text-xl text-ffblue'>{t('how.desc')}<br />{t('how.desc2')}<br />{t('how.desc3')}</p>
                        <div className='pt-12 text-center md:text-left'>
                            <div data-aos="fade-up" data-aos-delay="50" className='flex pb-8'>
                                <div className='basis-2/12'>
                                    <div className='rounded-full bg-ffbluelight text-ffblue font-semibold inline text-lg font-poppins px-6 py-4 mr-2 md:mr-0'>1</div>
                                </div>
                                <div className='basis-10/12 text-left'>
                                    <h4 className='font-semibold text-2xl md:text-2xl text-ffred pb-3'>{t('how.t1')}</h4>
                                    <p className='text-lg md:text-xl'>{t('how.p1')}</p>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="100" className='flex pb-8'>
                                <div className='basis-2/12'>
                                    <div className='rounded-full bg-ffbluelight text-ffblue font-semibold inline text-xl font-poppins px-6 py-4 mr-2 md:mr-0'>2</div>
                                </div>
                                <div className='basis-10/12 text-left'>
                                    <h4 className='font-semibold text-2xl md:text-2xl text-ffred pb-3'>{t('how.t2')}</h4>
                                    <p className='text-lg md:text-xl'>{t('how.p2')}</p>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-delay="150" className='flex pb-8'>
                                <div className='basis-2/12'>
                                    <div className='rounded-full bg-ffbluelight text-ffblue font-semibold inline text-xl font-poppins px-6 py-4 mr-2 md:mr-0'>3</div>
                                </div>
                                <div className='basis-10/12 text-left'>
                                    <h4 className='font-semibold text-2xl md:text-2xl text-ffred pb-3'>{t('how.t3')}</h4>
                                    <p className='text-lg md:text-xl'>{t('how.p3')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default How