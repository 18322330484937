import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef } from "react";
import { Link } from 'react-scroll';
import config from '../config/index.json';
import checkCheckbox from './checkCheckbox';
import '../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function FoodBusiness({ setInputValue }: Props) {

    const handleClick = () => {
      setInputValue('Soluzioni');
    };

    const { images } = config;
    const { food1, food2, food3, automizzato, close } = images;

    const { t } = useTranslation();

    useEffect(() => {
        const elements: NodeListOf<HTMLElement> = document.querySelectorAll('.item');
        elements.forEach((element) => {
            element.addEventListener('click', () => {
                const clickedElementId = element.id;
                console.log('1');

                const parentElement = document.querySelector('#root #foodGallery');
                if (parentElement) {
                    parentElement.classList.remove('item01', 'item02', 'item03');
                    parentElement.classList.add(clickedElementId);
                }
            });
        });
    }, []);

    const handleLinkClick = () => {
        handleClick();
    };

    const handleLinkClickPaper = () => {
        checkCheckbox();
        handleClick();
    };

    return (

        <section id="auto" className='pt-16 pb-24 border-[30px] border-[#E8477D]'>
            <div className='container relative pt-20'>
                    <span className='tagLabel block md:inline-block text-left text-white bg-[#E8477D] text-sm md:text-lg rounded-full pl-2 md:pl-6 pr-2 md:pr-12 py-3 font-poppins font-normal relative left-0 md:-left-4'>
                        <img src={automizzato} className='inline-block w-8 md:w-10 mr-2 md:mr-4' />
                        {t('business.tag')}
                </span>
                <h2 data-aos="fade-up" className='text-ffblue text-4xl md:text-4xl font-bold leading-tight pt-12 pb-6'>{t('business.title')}<br className="hidden md:block" />{t('business.title2')}</h2>
                <p data-aos="fade-up" data-aos-delay="250" className='text-xl md:text-xl text-ffblue max-w-[720px] pb-4'>{t('business.desc')}</p>
                <div id="foodGallery" className='md:grid grid-cols-2 text-left px-8 item01'>
                    <div className="pt-16">
                        <div id="item01" className="item">
                            <img src={food1} className='block md:hidden max-w-full photo01' alt="photo01" />
                            <div id="foodProvider" className='text-ffbluebright cursor-pointer font-poppins text-3xl md:text-2xl font-bold leading-tight pt-6 pb-2 md:pb-6 before:w-2 before:h-6 md:before:h-8 before:bg-ffbluebright before:absolute before:-ml-8 before:mt-.5'
                            >{t('business.t1')}</div>
                            <p className='text-base md:text-xl max-w-sm pb-24 md:pb-0'>{t('business.p1')}</p>
                        </div>
                        <div id="item02" className="item">
                            <img src={food2} className='block md:hidden max-w-ful photo02' alt="photo02" />
                            <div id="operatoriVending" className='text-ffbluebright cursor-pointer font-poppins text-3xl md:text-2xl font-bold leading-tight pt-6 pb-2 before:w-2 before:h-6 md:before:h-8 before:bg-ffbluebright before:absolute before:-ml-8 before:mt-.5'>{t('business.t2')}</div>
                            <p className='text-base md:text-xl max-w-sm pb-24 md:pb-0'>{t('business.p2')}</p>
                        </div>
                        <div id="item03" className="item">
                            <img src={food3} className='block md:hidden max-w-full photo03' alt="photo03" />
                            <div id="ristorazioneCollettiva" className='text-ffbluebright cursor-pointer font-poppins text-3xl md:text-2xl font-bold leading-tight pt-6 pb-2 md:pb-6 before:w-2 before:h-6 md:before:h-8 before:bg-ffbluebright before:absolute before:-ml-8 before:mt-.5'>{t('business.t3')}</div>
                            <p className='text-base md:text-xl max-w-sm pb-24 md:pb-0'>{t('business.p3')}</p>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="250" className='text-center relative'>
                        <img src={food1} className='hidden md:block max-w-full photo01' alt="photo01" />
                        <img src={food2} className='hidden md:block max-w-full absolute -top-2 -left-6 photo02' alt="photo02" />
                        <img src={food3} className='hidden md:block max-w-full absolute top-3 left-10 photo03' alt="photo03" />
                        <div className='relative z-10 -top-4'>
                            <Link
                                to='form'
                                smooth={true}
                                onClick={handleLinkClick}
                                className='cta font-poppins text-white bg-[#f83f76] px-7 py-3 relative text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30 btnDemo'>{t('requestDemo')}</Link><br /><br />
                            <Link
                                to='form'
                                smooth={true}
                                onClick={handleLinkClickPaper}
                                className='text-ffblue text-lg cursor-pointer transition underline hover:transition-all btnWhitepaper'>{t('downloadWhitepaper')}</Link>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FoodBusiness