import React from 'react';
import { NavLink as RouterLink } from "react-router-dom";
import { Link } from 'react-scroll';
import config from '../config/index.json';
import i18n from '../i18n';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';

function Footer() {
    const { images } = config;
    const { facebook, instagram, youtube, linkedin2, logo, up, pon, iconfb, iconyt, iconig, iconin, footHeadline, tornasu, tornasuWhite, backtopWhite, greenBtn } = images;
    const language = i18n.language;
    const imageSource = language === 'en' ? backtopWhite : tornasuWhite;

    const { t } = useTranslation();

    const navigation = [
        { name: t('navigation.frescofrigo'), href: t('href.frescofrigo') },
        { name: t('navigation.perche'), href: t('href.perche') },
        { name: t('navigation.advantages'), href: t('href.advantages') },
        { name: t('navigation.comefunziona'), href: t('href.comefunziona') },
        { name: t('navigation.tecnologia'), href: t('href.tecnologia') },
        { name: t('navigation.chisiamo'), href: t('href.chisiamo') }
    ];

    $(document).ready(function() {

        // Funkcja do ustawienia daty w LocalStorage
        const setHideDate = () => {
            const hideUntilDate = new Date();
            hideUntilDate.setDate(hideUntilDate.getDate() + 30); // Dodaj 30 dni
            localStorage.setItem('hideGreenUntil', hideUntilDate.toISOString());
        };

        // Funkcja do sprawdzenia daty w LocalStorage
        const shouldShowGreenDiv = (): boolean => {
            const hideUntil = localStorage.getItem('hideGreenUntil');
            if (hideUntil) {
                const hideUntilDate = new Date(hideUntil);
                return new Date() > hideUntilDate;
            }
            return true; // Jeśli nie ma daty w LocalStorage, pokaż div
        };

        const greenDiv = $('#Green');

        $('.close-button').on('click', function() {
            $('#Green').fadeOut();
            $('#greenBtn').fadeIn();
            setHideDate();
        });

        if (!shouldShowGreenDiv()) {
            $('#greenBtn').fadeIn();
            greenDiv.hide();
        } else {
            greenDiv.show();
        }
    
        $('#greenBtn').on('click', function() {
            $('#Green').fadeIn();
            $('#greenBtn').fadeOut();
        });
    });

    return (
        <section id='footer' className='bg-ffblue pt-16'>
            <div id='greenBtn'>
                <img src={greenBtn} />
            </div>
            <div id='Green'>
                <div className='close-button'></div>
                <div className='wrap'>
                    <span className='tag'>{t('green.tag')}</span>
                    <p dangerouslySetInnerHTML={{ __html: t('green.p') }}></p>
                    <ul>
                        <li dangerouslySetInnerHTML={{ __html: t('green.li1') }}></li>
                        <li dangerouslySetInnerHTML={{ __html: t('green.li2') }}></li>
                        <li dangerouslySetInnerHTML={{ __html: t('green.li3') }}></li>
                    </ul>
                </div>
            </div>
            <div className='md:flex flex-row gap-12 px-8 md:px-16'>
                <div className='basis-3/12'>
                    <img src={logo} className='logoFoot' />
                </div>
                <div className='basis-4/12'>
                    <ul className="items-center">
                        {navigation.map((item) => (
                            <li>
                            <Link
                            spy={true}
                            smooth={true}
                            duration={1000}
                            key={item.name}
                            to={item.href}
                            >
                            {item.name}
                            </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='basis-4/12'>
                    <ul className="items-center">
                        <li>
                            <RouterLink to="/bando/">Bando Nazionale Smart&Start Italia</RouterLink>
                        </li>
                        <li>
                            <a href={`${t('work.slug')}`}>{t('work.title')}</a>
                        </li>
                    </ul>
                </div>
                <div className='basis-4/12'>
                    <ul>
                        <li><a href='https://www.facebook.com/frescofrigo/' target='_blank'><img src={iconfb} className='w-2' /></a></li>
                        <li><a href='https://www.instagram.com/frescofrigo/' target='_blank'><img src={iconig} className='w-5' /></a></li>
                        <li><a href='https://www.youtube.com/channel/UCYFxiMavyy_Ttz_VJCRy4Xw/featured' target='_blank'><img src={iconyt}className='w-6' /></a></li>
                        <li><a href='https://www.linkedin.com/company/frescofrigo/' target='_blank'><img src={iconin} className='w-5' /></a></li>
                    </ul>
                </div>
                <div className='basis-4/12 flex flex-col md:place-items-end mt-8 md:mt-0'>
                    <a href='#'><img src={imageSource} className='w-8 md:w-16 backTopStatic' /></a>
                </div>
            </div>
            <div className='basis-1/1 px-8 md:px-16 pt-8 md:pt-16 pb-16'>
                <img src={footHeadline} className='w-full md:w-[80%] my-0 mx-auto' />
            </div>
            <div className='endBar bg-white md:flex flex-row px-8 md:px-16 pt-2'>
                <div>
                    <img src={pon} className='w-full max-w-[360px] mb-2' />
                </div>
                <div className='secNav'>
                    <RouterLink to="/come-pago/">{t('footer.secure')}</RouterLink>
                    <a href={t('footer.pLink') as string}>Privacy</a>
                    <a href="https://www.iubenda.com/privacy-policy/87567201/cookie-policy">Cookies</a>
                    <p>FrescoFrigo Srl - P.IVA 04585380233</p>
                </div>
            </div>
        </section>
    )
}

export default Footer