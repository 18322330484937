import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import config from '../config/index.json';
import '../i18n';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function About({ setInputValue }: Props) {
    const { images, links } = config;
    const { about, close, team01, team02, memb01, memb02, memb03, memb04, memb05, memb06, memb07, memb08, memb09, memb10, linkedin, aambizione, flag01, flag02, flag03, flag04 } = images;
    const { in01, in02, in03, in04, in05, in06, in07, in08, in09, in10 } = links;

    const { t } = useTranslation();

    const handleClick = () => {
        setInputValue('Chi siamo');
    };

    const handleLinkClick = () => {
        handleClick();
    };

    useEffect(() => {
        AOS.init({ duration: 2000 });
      }, []);

    return (
        <section id="about-us" className='pt-16 border-[30px] border-[#F8E691]'>
            <div className='container relative pt-32'>
                <div className='md:grid grid-cols-2'>
                    <div data-aos="fade-up" className='max-w-md text-center'>
                        <img src={about} className='max-w-full' />
                    </div>
                    <div className='text-center md:text-left'>
                    <span className='tagLabel block md:inline-block bg-[#F8E691] text-ffblue text-left text-sm md:text-lg rounded-full pl-2 md:pl-6 pr-2 md:pr-12 py-3 font-poppins font-normal relative left-0 md:-left-4'>
                            <img src={aambizione} className='inline-block w-8 md:w-10 mr-2 md:mr-4' />
                            {t('about.tag')}
                        </span>
                        <h2 data-aos="fade-up" className='text-ffblue pt-12 text-4xl md:text-4xl font-bold leading-tight pt-12 pb-6'>{t('about.title')}</h2>
                        <p data-aos="fade-up" data-aos-delay="250" className='text-xl md:text-xl text-ffblue w-11/12'>{t('about.p1')}<strong>{t('about.p2')}</strong>{t('about.p3')}<br/><br />{t('about.p4')}<br />{t('about.p5')}<u>{t('about.p6')}<strong>{t('about.p7')}</strong></u>.</p>
                    </div>
                </div>
                <div className='pt-32'>
                    <div className='text-center text-3xl font-poppins font-semibold text-ffbluebright pb-12'>{t('about.country')}</div>
                </div>
                <div className='pt-32 hidden grid grid-cols-2 text-center'>
                    <div className='mb-16'>
                        <div className='countryLabel text-center text-3xl font-poppins font-semibold text-ffbluebright pb-4'>{t('about.flag01')}</div>
                        <img className='w-16 mx-auto my-0' data-aos="zoom-in" data-aos-delay="250" src={flag03} />
                    </div>
                    <div className='mb-16'>
                        <div className='countryLabel text-center text-3xl font-poppins font-semibold text-ffbluebright pb-4'>{t('about.flag02')}</div>
                        <img className='w-16 mx-auto my-0' data-aos="zoom-in" data-aos-delay="250" src={flag04} />
                    </div>
                    <div className='mb-16'>
                        <div className='countryLabel text-center text-3xl font-poppins font-semibold text-ffbluebright pb-4'>{t('about.flag03')}</div>
                        <img className='w-16 mx-auto my-0' data-aos="zoom-in" data-aos-delay="250" src={flag02} />
                    </div>
                    <div className='mb-16'>
                        <div className='countryLabel text-center text-3xl font-poppins font-semibold text-ffbluebright pb-4'>{t('about.flag04')}</div>
                        <img className='w-16 mx-auto my-0' data-aos="zoom-in" data-aos-delay="250" src={flag01} />
                    </div>
                </div>
                <div className='grid grid-cols-2 pt-16 gap-16 place-content-center text-center'>
                    <div data-aos="fade-up">
                        <div className='bg-ffbluelight rounded-full mb-8 inline-block px-6 md:px-10 py-8'>
                            <img src={team01} className='w-24' />
                        </div>
                        <div className='text-center text-2xl md:text-2xl font-poppins font-semibold text-ffblue pb-12'>4<br />{t('about.f1')}</div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="250">
                        <div className='bg-ffbluelight rounded-full mb-8 inline-block px-6 md:px-10 py-8'>
                            <img src={team02} className='w-24' />
                        </div>
                        <div className='text-center text-2xl md:text-2xl font-poppins font-semibold text-ffblue pb-12'>18.000+<br />{t('about.f2')}<br /><span className='font-hanken text-2xl font-light'>{t('about.f3')} 1000+</span></div>
                    </div>
                </div>

                <div className='photoGrid photoGrid3 grid grid-cols-1 md:grid-cols-3 text-center font-poppins text-ffblue py-6 pt-16 mx-auto my-0 max-w-4xl gap-x-12 gap-y-8 md:gap-y-12 md:gap-24'>
                    <div data-aos="fade-up" className='relative'>
                        <img src={memb01} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Enrico Pandian</div>
                        <div className='text-sm md:text-md'>Founder & Co-Ceo</div>
                        <a href={in01} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                    <div data-aos="fade-up" className='relative'>
                        <img src={memb06} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Giovanna Zaaruolo</div>
                        <div className='text-sm md:text-md'>Board Member</div>
                        <a href={in06} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="50" className='relative'>
                        <img src={memb02} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Giovanni Conz</div>
                        <div className='text-sm md:text-md'>Chief Technology Officer</div>
                        <a href={in02} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                </div>

                <div className='photoGrid grid grid-cols-1 md:grid-cols-4 text-center font-poppins text-ffblue py-6 mx-auto my-0 max-w-4xl gap-x-12 gap-y-8 md:gap-y-12 md:gap-24'>
                    <div data-aos="fade-up" data-aos-delay="100" className='relative'>
                        <img src={memb03} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Alessia Moschella</div>
                        <div className='text-sm md:text-md'>Sales & Senior Account Manager</div>
                        <a href={in03} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="150" className='relative'>
                        <img src={memb04} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Elena Costa</div>
                        <div className='text-sm md:text-md'>Co-Ceo</div>
                        <a href={in04} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className='relative'>
                        <img src={memb05} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Giorgio Zett</div>
                        <div className='text-sm md:text-md'>Senior Full Stack Developer</div>
                        <a href={in05} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="150" className='relative'>
                        <img src={memb07} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Pablo Lizcano Sanchez Cruzado</div>
                        <div className='text-sm md:text-md'>Data Scientist</div>
                        <a href={in07} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                </div>

                <div className='photoGrid photoGrid3 grid grid-cols-1 md:grid-cols-3 text-center font-poppins text-ffblue py-6 pb-16 mx-auto my-0 max-w-4xl gap-x-12 gap-y-8 md:gap-y-12 md:gap-24'>
                    <div data-aos="fade-up" data-aos-delay="200" className='relative'>
                        <img src={memb08} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Laura Boaretti</div>
                        <div className='text-sm md:text-md'>Finance & Admin Specialist</div>
                        <a href={in08} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className='relative'>
                        <img src={memb09} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Alessandro Bocci</div>
                        <div className='text-sm md:text-md'>Senior Full Stack Developer</div>
                        <a href={in09} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200" className='relative'>
                        <img src={memb10} className='photoTeam' />
                        <div className='font-bold pt-6 text-md md:text-lg pb-2 md:leading-tight'>Alessandro Regano</div>
                        <div className='text-sm md:text-md'>Technical & After Sales Dep.</div>
                        <a href={in10} target="_blank" className='absolute top-2 md:top-4 -right-4 md:-right-8 w-8 md:w-7'><img src={linkedin} /></a>
                    </div>
                </div>

                <div className='py-8 pb-24 text-center text-ffblue'>
                    <p className='pb-8'>{t('about.ctap')}</p>
                    <Link
                        to='form'
                        smooth={true}
                        data-aos="fade-up" data-aos-delay="200"
                        onClick={handleLinkClick}
                        data-value='Chi siamo'
                        className='cta font-poppins text-white bg-[#f83f76] px-7 py-3 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30 btnWhitepaper'>{t('contact.btn')}</Link>
                    <p className='mt-12'>{t('about.l1')} <a href="https://frescofrigo.com/lavora-con-noi/"><u>{t('about.l2')}</u></a></p>
                </div>
            </div>
        </section>
    )
}

export default About