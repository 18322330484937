import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import config from './config/index.json';

function Bando() {

    const { images } = config;
    const { logo } = images;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    

    return (
        <div>
            <div className='bg-ffblue py-24'>
                <div className='container'>
                    <div className='text-center pb-24'>
                        <RouterLink to="/">
                            <img src={logo} className="my-4 w-52 mx-auto" alt="logo" />
                        </RouterLink>
                    </div>
                    <h1 className='text-white font-poppins md:text-7xl text-4xl px-4 font-bold pb-12 text-center'>La rivoluzione Retail in 1 MQ</h1>
                    <div className='max-w-[640px] mx-auto my-0 px-8 md:px-0'>
                        <p className='text-white text-xl font-medium'>FrescoFrigo nasce nel 2018 come Tech Company made in Italy con 4M€ investiti nella tecnologia per hardware e software all’avanguardia.<br /><br />Vantiamo un'esperienza pluriennale nella gestione diretta di una flotta di frigoriferi installati in uffici e aziende durante la nostra prima fase del progetto.<br /><br />Oggi FrescoFresco vende frigoriferi e relativa tecnologia, <strong>mettendo il proprio know-how a disposizione dei propri partner, aiutandoli a scalare il loro business in maniera veloce e smart</strong>, attraverso uno smart fridge automatizzato affidabile e plug&play e relativo software.</p>
                        <h3 className='text-white font-poppins text-3xl md:text-4xl font-bold pt-16 pb-4'>Obiettivi</h3>
                        <p className='text-white text-xl font-medium'><strong>FrescoFrigo ha l’obiettivo di diventare il Leader di mercato nelle soluzioni di Retail Automation.</strong><br /><br />FrescoFrigo è <strong>l’enabler tecnologico</strong> per le centinaia di aziende Food e Vending che intendano sviluppare il business dello smart fridge, ovunque nel mondo.</p>
                        <h3 className='text-white font-poppins text-3xl md:text-4xl font-bold pt-16 pb-4'>Risultati attesi</h3>
                        <p className='text-white text-xl font-medium pb-4'>Attraverso la realizzazione della presente iniziativa, è stato possibile realizzare uno smart fridge che riuscisse da un lato a consentire un’esperienza utente molto semplice ed intuitiva, dall’altro che rispondesse a tutta una serie di necessità dei gestori volte al controllo da remoto,  e alla gestione predittiva delle operations.<br /><br />Pertanto la realizzazione degli obiettivi si  è concentrata maggiormente nel:<br /></p>
                        <ol className='text-white text-xl font-medium list-decimal pl-4'>
                            <li className='py-2'>Affinamento tecnologico della soluzione tramite l’implementazione di strategie all'avanguardia per l’ottimizzazione dei sistemi e della soluzione;</li>
                            <li className='py-2'>Potenziamento della dotazione hardware;</li>
                            <li className='py-2'>Miglioramento dell’infrastruttura tecnologica e ampliamento delle features disponibili, tramite il rafforzamento delle capacità tecniche e tecnologiche aziendali;</li>
                            <li className='py-2'>Incremento delle conoscenze di Data analysis tramite consulenze da parte di esperti specializzati</li>
                            <li className='py-2'>sviluppo e ricerca di  nuove tecnologie e materiali per rispondere alle richieste dei nostri partner, come ad esempio:<br />
                                <span className='py-2 inline-block'>5a.  affinare lo sviluppo software e la ricerca di materiali in particolare per algoritmi di AI e  Tecnologia RFID (Radio-Frequency Identification)</span><br />
                                <span className='py-2 inline-block'>5b.  Focalizzazione delle risorse sullo sviluppo di un ulteriore business che consenta di avere nel breve periodo una risposta positiva in termini di fatturato, come la vendita diretta della frigovetrina.</span><br />
                                <span className='py-2 inline-block'>5c.  Integrazione sulla frigovetrina del dispositivo POS/Carta di credito, ottimizzando in questo modo l’user experience dell’utente.</span></li>
                        </ol>
                        <h3 className='text-white font-poppins text-3xl md:text-4xl font-bold pt-16 pb-4'>Sostegno finanziario ricevuto</h3>
                        <p className='text-white text-xl font-medium'>La proposta imprenditoriale è tra i beneficiari dell’agevolazione prevista dal Bando Smart & Start  e  il contributo finanziato sarà pari ad un importo massimo di € 711.622,00</p>
                        <br /><br /><br /><br />
                        <p className='text-sm text-white text-center'>FrescoFrigo Srl - P.IVA 04585380233</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bando;