import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import config from '../config/index.json';
import '../i18n';
import { useTranslation } from 'react-i18next';

import FeaturesSlider from './FeaturesSlider';

function Features() {
    const { images } = config;
    const { cervellone } = images;

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 2000 });
      }, []);

    return (
        <section id='frescofrigo' className='pt-36 pb-12 md:pb-36'>
            <div className='container px-8 md:px-0 text-center md:text-left'>
                <span data-aos="fade-up" className='block md:inline-block text-left text-ffblue bg-ffbluelight text-sm md:text-lg rounded-full pl-2 md:pl-6 pr-2 md:pr-12 py-3 font-poppins font-normal relative left-0 md:-left-4'>
                    <img src={cervellone} className='inline-block w-8 md:w-8 mr-2 md:mr-4' />
                    {t('features.tag')}
                </span>
                <h2 data-aos="fade-up" className='text-ffblue text-4xl md:text-4xl font-bold leading-tight pt-12 pb-6'>{t('features.title')}</h2>
                <p data-aos="fade-up" data-aos-delay="200" className='text-xl md:text-xl text-ffblue max-w-[500px]'>{t('features.desc')}</p>
            </div>
            <div className='container sliderContainer'>
                <FeaturesSlider data-aos="fade-up" />
            </div>
        </section>
    )
}

export default Features