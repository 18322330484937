import React, { useState, useEffect } from 'react';
import config from '../config/index.json';
import Perche from './Perche';
import About from './About';
import FoodBusiness from './FoodBusiness';
import Benefits from './Benefits';
import '../i18n';
import { useTranslation } from 'react-i18next';

const { images } = config;
const { iconSpaceship, iconLightbulb, iconHeart, iconFingerprint, iconHeartHover, close, arrowRightWhite, arrowRightBlue, closeBlue} = images;

interface Props {
    setInputValue: (value: string) => void;
}

function Windows({ setInputValue }: Props) {

    const [sourceValue, setSourceValue] = useState('');

    const handleSetSourceValue = (value: string) => {
      setSourceValue(value);
    };

    const { t } = useTranslation();

    const [activeSection, setActiveSection] = useState<string | null>(null);

    const openSection = (sectionId: string) => {
        setActiveSection(sectionId);
        scrollToSection(sectionId);
    };

    const closeSection = () => {
        setActiveSection(null);
    };

    const wrapperClasses = `relative pb-48 ${activeSection ? 'open' : ''}`;

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById('windows');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section id='windows' className={wrapperClasses}>
            <div id='percheNav'>
                <div className={`section-content relative ${activeSection === 'vantaggi' ? 'active' : ''}`}>
                    <span className='bg-[#A0D882] rounded-tl-full rounded-bl-full block top-12 right-0 float-right sticky w-24 mt-16 cursor-pointer z-50' onClick={closeSection}>
                        <img id='close' src={close} className='block w-8 h-8 m-4' />
                    </span>
                    <Benefits setInputValue={setSourceValue} />
                </div>
                <div className={`section-content relative ${activeSection === 'partner' ? 'active' : ''}`}>
                    <span className='bg-[#E8477D] rounded-tl-full rounded-bl-full block top-12 right-0 float-right sticky w-24 mt-16 cursor-pointer z-50' onClick={closeSection}>
                        <img id='close' src={close} className='block w-8 h-8 m-4' />
                    </span>
                    <FoodBusiness setInputValue={setSourceValue} />
                </div>
                <div className={`section-content relative ${activeSection === 'perche' ? 'active' : ''}`}>
                    <span className='bg-[#00A8C7] rounded-tl-full rounded-bl-full block top-12 right-0 float-right sticky w-24 mt-16 cursor-pointer z-50' onClick={closeSection}>
                        <img id='close' src={close} className='block w-8 h-8 m-4' />
                    </span>
                    <Perche setInputValue={setSourceValue} />
                </div>
                <div className={`section-content relative ${activeSection === 'siamo' ? 'active' : ''}`}>
                    <span className='bg-[#F8E691] rounded-tl-full rounded-bl-full block top-12 right-0 float-right sticky w-24 mt-16 cursor-pointer z-50' onClick={closeSection}>
                        <img id='close' src={close} className='block w-8 h-8 m-4' />
                    </span>
                    <About  setInputValue={setSourceValue} />
                </div>
            </div>
            <div id="boxes">
                <div className='firstRow'>
                    <div onClick={() => openSection('perche')}>
                        <div className='boxItem boxItem01'>
                            <div className='wrap place-self-end'>
                                <div className='ico relative z-10 mb-4 w-16'>
                                    <img src={iconHeart} />
                                    <img src={iconHeartHover} className='absolute top-0 left-0 opacity-0 hoverIcon' />
                                </div>
                                <h3 className='text-5xl mb-2'>{t('perche.h31')}</h3>
                                <span>{t('perche.h32')}</span>
                                <div className='underHover'>
                                    <p className='font-normal mb-6'>{t('perche.intro')}</p>
                                    <img src={arrowRightWhite} className='arrowRight' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='boxItem boxItem02' onClick={() => openSection('partner')}>
                            <div className='wrap'>
                                <img src={iconFingerprint} className='ico relative z-10 mb-4 w-16' />
                                <h3 className='text-5xl mb-2'>{t('partner.h31')}</h3>
                                <span>{t('partner.h32')}</span>
                                <div className='underHover'>
                                    <p className='font-normal mb-6'>{t('partner.intro')}</p>
                                    <img src={arrowRightWhite} className='arrowRight' />
                                </div>
                            </div>
                        </div>
                        <div className='boxItem boxItem03' onClick={() => openSection('vantaggi')}>
                            <div className='wrap'>
                                <img src={iconLightbulb} className='ico relative z-10 mb-4 w-16' />
                                <h3 className='text-5xl mb-2'>{t('van.h31')}</h3>
                                <span>{t('van.h32')}</span>
                                <div className='underHover'>
                                    <p className='font-normal mb-6'>{t('van.intro')}</p>
                                    <img src={arrowRightWhite} className='arrowRight' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='siamoNav' className='scndRow'>
                    <div className='boxItem boxItem04' onClick={() => openSection('siamo')}>
                        <div className='wrap'>
                            <img src={iconSpaceship} className='ico relative z-10 mb-4 w-16' />
                            <h3 className='text-5xl mb-2'>{t('siamo.h31')}</h3>
                            <span>{t('siamo.h32')}</span>
                            <div className='underHover'>
                                <p className='font-normal mb-6 text-ffblue'>{t('siamo.intro')}</p>
                                <img src={arrowRightBlue} className='arrowRight' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Windows;
