import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Slider from "react-slick";
import { Link } from 'react-scroll';
import { VideoPlayer } from "./Video";
import config from '../config/index.json';
import '../i18n';
import { useTranslation } from 'react-i18next';

function Smart() {
    const { navigation, images } = config;
    const { smartPlaceholder, smartIco1, smartIco2, smartIco3, smartIco4, tablet, tech01, tech02, tech03, tech04 } = images;

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const settings = {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <section id={t('href.tecnologia') as string} className='py-16 md:py-24 my-6 md:my-16 bg-ffred'>
            <div className='container px-8 md:px-0'>
                <div className='md:flex place-items-end pb-16 md:pb-16 text-center md:text-left'>
                    <div className='basis-5/12 md:pr-4 pr-0 pb-8 md:pb-24'>
                        <h3 data-aos="fade-up" className='text-white text-4xl md:text-5xl font-bold pt-12 pb-6'>{t('smart.title')}</h3>
                        <p data-aos="fade-up" data-aos-delay="250" className='text-xl md:text-xl text-white pb-6 pr-0 md:pr-12'>{t('smart.desc')}</p>
                    </div>
                    <div className='basis-7/12 w-1/1 md:w-5/12 text-right relative'>
                        <div className='howSliderWrap w-full flex before:bg-white/20 before:rounded-3xl before:w-full before:absolute before:bottom-0 before:h-[50%] before:left-0'>
                            <Slider {...settings} className="howSlider w-full relative">
                                <div className='pt-20 relative'>
                                    <span className='absolute top-0 left-0 text-ffred bg-white rounded-full px-0 py-2 font-bold text-xl font-poppins inline-block w-11 text-center'>1</span>
                                    <div className='relative max-w-[80%] my-0 mx-auto'>
                                        <VideoPlayer
                                            hlsSrc={tech01}
                                            posterSrc=""
                                            className='absolute left-[4.5%] top-[4.5%] max-w-[90.5%] w-full'
                                        />
                                        <img src={tablet} className='relative z-10' />
                                    </div>
                                    <p className='pt-8 pb-16 px-4 text-white font-semibold text-md text-center max-w-lg my-0 mx-auto'>{t('smart.s1')}</p>
                                </div>
                                <div className='pt-20 relative'>
                                    <span className='absolute top-0 left-0 text-ffred bg-white rounded-full px-0 py-2 font-bold text-xl font-poppins inline-block w-11 text-center'>2</span>
                                    <div className='relative max-w-[80%] my-0 mx-auto'>
                                        <VideoPlayer
                                            hlsSrc={tech02}
                                            posterSrc=""
                                            className='absolute left-[4.5%] top-[4.5%] max-w-[90.5%] w-full'
                                        />
                                        <img src={tablet} className='relative z-10' />
                                    </div>
                                    <p className='pt-8 pb-16 px-4 text-white font-semibold text-md text-center max-w-lg my-0 mx-auto'>{t('smart.s2')}</p>
                                </div>
                                <div className='pt-20 relative'>
                                    <span className='absolute top-0 left-0 text-ffred bg-white rounded-full px-0 py-2 font-bold text-xl font-poppins inline-block w-11 text-center'>3</span>
                                    <div className='relative max-w-[80%] my-0 mx-auto'>
                                        <VideoPlayer
                                            hlsSrc={tech03}
                                            posterSrc=""
                                            className='absolute left-[4.5%] top-[4.5%] max-w-[90.5%] w-full'
                                        />
                                        <img src={tablet} className='relative z-10' />
                                    </div>
                                    <p className='pt-8 pb-16 px-4 text-white font-semibold text-md text-center max-w-lg my-0 mx-auto'>{t('smart.s3')}</p>
                                </div>
                                <div className='pt-20 relative'>
                                    <span className='absolute top-0 left-0 text-ffred bg-white rounded-full px-0 py-2 font-bold text-xl font-poppins inline-block w-11 text-center'>4</span>
                                    <div className='relative max-w-[80%] my-0 mx-auto'>
                                        <VideoPlayer
                                            hlsSrc={tech04}
                                            posterSrc=""
                                            className='absolute left-[4.5%] top-[4.5%] max-w-[90.5%] w-full'
                                        />
                                        <img src={tablet} className='relative z-10' />
                                    </div>
                                    <p className='pt-8 pb-16 px-4 text-white font-semibold text-md text-center max-w-lg my-0 mx-auto'>{t('smart.s4')}</p>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-12'>
                    <div data-aos="fade-up" data-aos-delay="50">
                        <div className='bg-white rounded-full px-4 py-4 inline-block'><img src={smartIco1} className='w-8 fill-ffred' /></div>
                        <h4 className='text-white text-lg font-semibold pt-4'>{t('smart.b1')}</h4>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="100">
                        <div className='bg-white rounded-full px-4 py-4 inline-block'><img src={smartIco2} className='w-8 fill-ffred' /></div>
                        <h4 className='text-white text-lg font-semibold pt-4'>{t('smart.b2')}</h4>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="150">
                        <div className='bg-white rounded-full px-4 py-4 inline-block'><img src={smartIco3} className='w-8 fill-ffred' /></div>
                        <h4 className='text-white text-lg font-semibold pt-4'>{t('smart.b3')}</h4>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="200">
                        <div className='bg-white rounded-full px-4 py-4 inline-block'><img src={smartIco4} className='w-8 fill-ffred' /></div>
                        <h4 className='text-white text-lg font-semibold pt-4'>{t('smart.b4')}</h4>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Smart