import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Slider from "react-slick";
import { VideoPlayer } from "./Video";
import config from '../config/index.json';
import '../i18n';
import { useTranslation } from 'react-i18next';

function FeaturesSlider() {
    const { images } = config;
    const { illus01, illus02, illus03, illus04, illus01on, illus02on, illus03on, illus04on } = images;

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const settings = {
        className: "slider variable-width",
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        variableWidth: true,
        focusOnSelect: true,
        responsive: [
            {
              breakpoint: 1150,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                variableWidth: false,
              }
            }
        ]
    };

      return (
        <Slider {...settings} className="featSlider w-full py-8 md:py-12 px-4 md:px-16 md:flex items-center place-content-center gap-6">
            <div className="element cursor-pointer active">
                <div className="flex items-center items-center">
                    <div className="caption">
                        <div className="text-neutral-400">1/4</div>
                        <h3 className="text-ffred text-xl md:text-3xl font-bold py-1 md:py-4">{t('features.t1')}</h3>
                        <p className="text-ffblue text-sm md:text-lg">{t('features.p1')}</p>
                    </div>
                    <div className="thumb">
                        <img src={illus01on} alt="illus01" className="on" />
                        <img src={illus01} alt="illus01" />
                    </div>
                </div>
            </div>
            <div className="element cursor-pointer">
                <div className="flex place-content-center items-center">
                    <div className="caption">
                        <div className="text-neutral-400">2/4</div>
                        <h3 className="text-ffred text-xl md:text-3xl font-bold py-1 md:py-4">{t('features.t2')}</h3>
                        <p className="text-ffblue text-sm md:text-lg md:leading-tight">{t('features.p2')}</p>
                    </div>
                    <div className="thumb">
                        <img src={illus02on} alt="illus02" className="on" />
                        <img src={illus02} alt="illus02" />
                    </div>
                </div>
            </div>
            <div className="element cursor-pointer">
                <div className="flex place-content-center items-center">
                    <div className="caption">
                        <div className="text-neutral-400">3/4</div>
                        <h3 className="text-ffred text-xl md:text-3xl font-bold py-1 md:py-4">{t('features.t3')}</h3>
                        <p className="text-ffblue text-sm md:text-lg leading-normal">{t('features.p3')}</p>
                    </div>
                    <div className="thumb">
                        <img src={illus03on} alt="illus03" className="on" />
                        <img src={illus03} alt="illus03" />
                    </div>
                </div>
            </div>
            <div className="element cursor-pointer">
                <div className="flex place-content-center items-center">
                    <div className="caption">
                        <div className="text-neutral-400">4/4</div>
                        <h3 className="text-ffred text-xl md:text-3xl font-bold py-1 md:py-4">{t('features.t4')}</h3>
                        <p className="text-ffblue text-sm md:text-lg">{t('features.p4')}</p>
                    </div>
                    <div className="thumb">
                        <img src={illus04on} alt="illus04" className="on" />
                        <img src={illus04} alt="illus04" />
                    </div>
                </div>
            </div>
        </Slider>
    )
}

export default FeaturesSlider