import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-scroll';
import React, { useEffect } from 'react';
import config from '../config/index.json';
import '../i18n';
import { useTranslation } from 'react-i18next';

function Industria() {

    const { images } = config;
    const { industria } = images;

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const { i18n } = useTranslation();

    if (i18n.language === 'en') {
        return null;
    } else {
        return (
            <section className='pt-16 pb-32'>
                <div className='container md:px-0 px-8'>
                    <div className='md:flex'>
                        <div className='basis-3/12 md:pr-16 pt-12 text-center'>
                            <img src={industria} className='w-full max-w-[120px] md:max-w-[75%] mx-auto my-0' />
                        </div>
                        <div className='basis-8/12 text-center md:text-left'>
                            <h2 data-aos="fade-up" className='text-ffblue text-center md:text-left text-4xl md:text-3xl font-bold md:leading-tight pt-12 pb-6'>FrescoFrigo è certificata Industria 4.0</h2>
                            <p data-aos="fade-up" data-aos-delay="300" className='text-xl text-center md:text-left md:text-lg text-ffblue pb-6'>Il nostro prodotto è stato certificato Industria 4.0 secondo la normativa prevista, pertanto in caso di acquisto <strong>potrai usufruire del credito d'imposta</strong>.</p>
                            <a href="https://www.agenziaentrate.gov.it/portale/web/guest/investimenti-in-beni-strumentali/infogen-investimenti-in-beni-strumentali-imprese" target="_blank" className='cta font-poppins text-white bg-ffblue px-7 py-3 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30 btnWhitepaper'>Scopri di più</a>
                        </div>
                    </div>
                    
                </div>
            </section>
        );
    }
}

export default Industria