import React from 'react';
import { Link } from 'react-scroll';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { VideoPlayer } from "./Video";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import config from '../config/index.json';

interface Props {
    setInputValue: (value: string) => void;
}

function Hero({ setInputValue }: Props) {

    const { t } = useTranslation();

    const handleClick = () => {
      setInputValue('Hero');
    };

    const { images } = config;
    const { hero01, hero02, headerVideo } = images;

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <section id='hero' className='bg-ffblue'>
            <div className='container'>
                <div className='flex flex-col md:flex-row px-8 md:px-0 items-center'>
                    <div className='basis-4/12 text-center md:text-left pt-24 pb-36'>
                        <h1 data-aos="fade-down" className='text-white text-center md:text-left text-4xl font-bold pb-4'>{t('hero.title')}</h1>
                        <div className='block md:hidden basis-1/2 relative flex justify-end'>
                            <VideoPlayer
                                hlsSrc={headerVideo}
                                posterSrc=""
                                className='w-full'
                            />
                        </div>
                        <p data-aos="fade-up" className='text-white text-center md:text-left text-xl md:pr-4'>{t('hero.p')}</p><br /><br />
                        <Link
                        to='form'
                        smooth={true}
                        onClick={handleClick}
                        className='text-ffblue bg-white px-6 py-3 text-lg rounded-full font-bold cursor-pointer cta btnDemo'>{t('requestDemo')}</Link>
                    </div>
                    <div className='hidden md:flex basis-8/12 pl-8 relative flex justify-end mt-32 md:mt-0'>
                        <VideoPlayer
                            hlsSrc={headerVideo}
                            posterSrc=""
                            className='w-full'
                        />
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default Hero